<template>
  <div ref="el" v-clickaway="hide" class="relative inline-block h-full">
    <div class="h-full flex items-center" @click="toggle">
      <slot :is-visible="isVisible" />
      <BaseIcon
        v-if="showArrow"
        name="outline_chevron_down"
        class="flex-shrink-0 opacity-20 ml-2 cursor-pointer transform transition duration-100 ease-in-out"
        :class="isVisible && 'rotate-180'"
        size="md"
      />
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-show="isVisible"
        ref="menu"
        class="absolute"
        :class="[
          proxyLevel,
          proxyDropDownWidth,
          canOpenLeft ? 'right-0' : 'left-0',
          canOpenBottom ? (withOffset ? 'top-full' : 'top-0') : 'bottom-full',
          `origin-${canOpenBottom ? 'top' : 'bottom'}-${
            canOpenLeft ? 'right' : 'left'
          }`,
        ]"
        @click="onClick"
      >
        <slot name="menu" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

const dropDownWidthMap = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  full: 'w-full',
}

const props = defineProps({
  parent: {
    type: Object as PropType<HTMLElement>,
    default: undefined,
  },
  level: {
    type: [String, Number],
    default: 10,
  },
  hideOnClick: {
    type: Boolean,
    default: true,
  },
  dropDownWidth: {
    type: String as PropType<keyof typeof dropDownWidthMap>,
    default: 'xs',
  },
  withOffset: {
    type: Boolean,
    default: true,
  },
  showArrow: {
    type: Boolean,
    default: false,
  },
  preferBottom: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['show', 'hide'])

const canOpenLeft = ref(false)
const canOpenBottom = ref(false)
const isVisible = ref(false)
const el = ref<null | HTMLElement>(null)
const menu = ref<null | HTMLElement>(null)

const proxyLevel = computed(() => {
  return props.level && `z-${props.level}`
})

const proxyDropDownWidth = computed(() => {
  return props.dropDownWidth && dropDownWidthMap[props.dropDownWidth]
})

function toggle() {
  if (isVisible.value) {
    hide()
  } else {
    show()

    nextTick(() => {
      checkPositionX()
      checkPositionY()
    })
  }
}

function hide() {
  isVisible.value = false
  emit('hide')
}

function show() {
  isVisible.value = true
  emit('show')
}

function checkPositionX() {
  if (!el.value || !menu.value) {
    throw new Error('no element or menu references')
  }
  const rectEl = el.value?.getBoundingClientRect()
  const rectMenu = menu.value?.getBoundingClientRect()

  if (props.parent) {
    const rectParent = props.parent.getBoundingClientRect()

    canOpenLeft.value = rectEl.left - rectParent.left > rectMenu.width
  } else {
    canOpenLeft.value = rectEl.left + rectEl.width > rectMenu.width
  }
}

function checkPositionY() {
  if (!el.value || !menu.value) {
    throw new Error('no element or menu references')
  }
  const rectEl = el.value.getBoundingClientRect()
  const rectMenu = menu.value.getBoundingClientRect()

  if (props.parent) {
    const rectParent = props.parent.getBoundingClientRect()

    canOpenBottom.value = rectEl.top + rectMenu.height + 50 < rectParent.bottom
  } else {
    const canOpenUp = rectEl.top > rectMenu.height + 50
    const canOpenDown =
      window.innerHeight - rectEl.bottom > rectMenu.height + 50

    if ((canOpenUp && canOpenDown) || (!canOpenUp && !canOpenDown)) {
      canOpenBottom.value = props.preferBottom
    } else {
      canOpenBottom.value = props.preferBottom ? canOpenDown : !canOpenUp
    }
  }
}

function onClick() {
  if (props.hideOnClick) {
    hide()
  }
}
</script>
